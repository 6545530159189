<template lang="pug">
div
  v-card.mb-3
    v-toolbar(flat)
      v-toolbar-title Список отчетов
    template(v-if="$root.isLoading('reports_list')")
      v-progress-linear(indeterminate)
      v-card-text.text-center Загрузка доступных отчетов

  v-row(dense)
    v-col(cols="6" v-for="report in availableReports" :key="report.id")
      v-card.d-flex.flex-column.justify-space-between(style="height: 100%;")
          v-card-title {{report.name}}
          v-card-text
            span(v-if="report.description") {{report.description}} <br>
            span {{desctiption(report)}}
          v-card-actions
            v-btn.mx-auto(color="primary" text @click="changeReport(report.id)") Открыть
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    changeReport: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('REPORTS', ['availableReports']),

    desctiption() {
      return report => {
        const result = [];

        if (report.form === 'EmptyForm') result.push('Без фильтров');
        if (report.chart) result.push('Можно построить график');

        const downloadableFormats = report.availableFormats.filter(
          item => item !== 'json',
        );
        if (downloadableFormats.length)
          result.push('Можно скачать как .' + downloadableFormats.join(' .'));

        return result.join('. ');
      };
    },
  },
};
</script>
